import { BreakpointObserver } from '@angular/cdk/layout';
import { computed, inject, Injectable, makeStateKey, TransferState } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import algoliasearch from 'algoliasearch';
import { map } from 'rxjs';

import { CurrentSiteState } from '@ppg/configuration';
import { ALGOLIA_CONFIGURATION_KEY, Patterns } from '@ppg/core/constants';
import { Breakpoints, ClientSiteSettingsKeys, ResourceItem } from '@ppg/core/enums';
import { LanguagesService } from '@ppg/core/language';
import { AlgoliaConfigs } from '@ppg/core/models';
import { CdeGtmService } from '@ppg/features/gtm';
import { ResourceService } from '@ppg/shared/resource';

import {
  MAX_CHARACTERS_CONFIG_DESKTOP,
  MAX_CHARACTERS_CONFIG_MOBILE,
  MAX_CHARACTERS_CONFIG_TABLET,
} from '../../consts/max-characters-configs.const';
import { AlgoliaIndexPlaceholders } from '../../enums/algolia-index-placeholders.enum';

@Injectable({ providedIn: 'root' })
export class FederatedSearchService {
  private readonly HITS_PER_PAGE_DESKTOP = 4;
  private readonly HITS_PER_PAGE_MOBILE = 1;

  private readonly resourceService = inject(ResourceService);
  private readonly currentSiteState = inject(CurrentSiteState);
  private readonly transferState = inject(TransferState);
  private readonly languagesService = inject(LanguagesService);
  private readonly breakpointObserver = inject(BreakpointObserver);
  private readonly cdeGtmService = inject(CdeGtmService);

  private readonly ALGOLIA_KEY = makeStateKey<AlgoliaConfigs>(ALGOLIA_CONFIGURATION_KEY);
  private readonly algoliaConfigs = this.transferState.get<AlgoliaConfigs | null>(this.ALGOLIA_KEY, null);

  private readonly leadGenIndexingEnabled = computed(() =>
    this.currentSiteState.getSiteFlag(ClientSiteSettingsKeys.LeadGenIndexingEnabled),
  );
  private readonly productIndexingEnabled = computed(() =>
    this.currentSiteState.getSiteFlag(ClientSiteSettingsKeys.ProductIndexingEnabled),
  );
  private readonly colorIndexingEnabled = computed(() =>
    this.currentSiteState.getSiteFlag(ClientSiteSettingsKeys.ColorIndexingEnabled),
  );
  private readonly colorJourneyColorCodeHidden = computed(() =>
    this.currentSiteState.getSiteFlag(ClientSiteSettingsKeys.ColorJourneyColorCodeHidden),
  );

  private readonly localizationFilter = computed(() => `localization:${this.languagesService.language()}`);
  private readonly catalogFilter = computed(
    () =>
      this.currentSiteState
        .currentSite()
        ?.algoliaCatalog?.map((catalog) => `catalog:${catalog.catalogNameFromAlgolia}`) || [],
  );
  public readonly contentFilters = computed(() => [this.localizationFilter(), this.catalogFilter()]);

  public readonly colorIdDisplay = computed(() => !this.colorJourneyColorCodeHidden());

  public readonly contentIndex = computed(() =>
    this.leadGenIndexingEnabled() ? this.algoliaConfigs?.contentIndex : null,
  );
  public readonly productIndex = computed(() =>
    this.productIndexingEnabled()
      ? this.algoliaConfigs?.productIndex
          .replace(
            AlgoliaIndexPlaceholders.SiteName,
            this.currentSiteState.currentSite()?.name?.replace(Patterns.OnlyLettersAndDigits, '_') || '',
          )
          .replace(AlgoliaIndexPlaceholders.Language, this.languagesService.language().replace('-', '_'))
          .toLowerCase()
      : null,
  );

  public readonly colorIndex = computed(() =>
    this.colorIndexingEnabled()
      ? this.algoliaConfigs?.colorIndex
          .replace(
            AlgoliaIndexPlaceholders.SiteName,
            this.currentSiteState.currentSite()?.name?.replace(Patterns.OnlyLettersAndDigits, '_') || '',
          )
          .replace(AlgoliaIndexPlaceholders.Language, this.languagesService.language().replace('-', '_'))
          .toLowerCase()
      : null,
  );

  public readonly cdeUserCookie = this.algoliaConfigs?.cdeUserCookie || '';

  public readonly searchClient = algoliasearch(
    this.algoliaConfigs?.cdeApplicationId || '',
    this.algoliaConfigs?.cdeSecuredKey || '',
  );

  public readonly hitsPerPage = toSignal(
    this.breakpointObserver
      .observe(Breakpoints.Mobile)
      .pipe(map(({ matches }) => (matches ? this.HITS_PER_PAGE_MOBILE : this.HITS_PER_PAGE_DESKTOP))),
    { initialValue: this.HITS_PER_PAGE_DESKTOP },
  );

  public readonly maxCharactersConfiguration = toSignal(
    this.breakpointObserver
      .observe([Breakpoints.Desktop, Breakpoints.Tablet, Breakpoints.Mobile])
      .pipe(
        map(({ breakpoints }) =>
          breakpoints[Breakpoints.Desktop]
            ? MAX_CHARACTERS_CONFIG_DESKTOP
            : breakpoints[Breakpoints.Tablet]
              ? MAX_CHARACTERS_CONFIG_TABLET
              : MAX_CHARACTERS_CONFIG_MOBILE,
        ),
      ),
  );

  public readonly textResources = computed(() => ({
    searchAll: this.resourceService.getResourceByKey(ResourceItem.FEDERATED_SEARCH_SEARCH_ALL),
    found: this.resourceService.getResourceByKey(ResourceItem.FEDERATED_SEARCH_FOUND),
    products: this.resourceService.getResourceByKey(ResourceItem.FEDERATED_SEARCH_PRODUCTS),
    colors: this.resourceService.getResourceByKey(ResourceItem.FEDERATED_SEARCH_COLORS),
    all: this.resourceService.getResourceByKey(ResourceItem.FEDERATED_SEARCH_ALL),
    searchPlaceholder: this.resourceService.getResourceByKey(ResourceItem.SITE_SEARCH_PLACEHOLDER),
    noResultsMessage: this.resourceService.getResourceByKey(ResourceItem.SEARCH_PAGE_GLOBAL_NO_RESULT_MESSAGE),
  }));

  public pushSubmitSearchResults(searchTerm: string) {
    this.cdeGtmService.pushTag({
      event: 'view_search_results',
      searchTerm,
      searchType: 'standard',
    });
  }

  public pushLiveSearchResults(searchTerm: string) {
    this.cdeGtmService.pushTag({
      event: 'view_search_results',
      searchTerm,
      searchType: 'live_results',
    });
  }

  public pushSearchAllResults(searchTerm: string, searchAllType: string) {
    this.cdeGtmService.pushTag({
      event: 'view_search_results',
      searchTerm,
      searchType: 'live_results_search_all',
      searchAllType,
    });
  }
}
